import { createAsync } from '@solidjs/router';
import { Show } from 'solid-js';
import { Error404, gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { OldCardCtx } from '../../../providers/card';
import type { RouteSectionProps } from '@solidjs/router';

export default function OldTroonCard(props: RouteSectionProps) {
	const card = createAsync(() => getCard({ groupId: props.params.groupId! }), { deferStream: true });
	return (
		<Show when={card()}>
			<OldCardCtx.Provider value={card}>{props.children}</OldCardCtx.Provider>
		</Show>
	);
}

const cardQuery = gql(`
query card($groupId: String!) {
  card: oldTroonCardGroupInfo(groupId: $groupId) {
    group {
      id
      name
      combinedName
      cardIds
    }
    states {
      id
      name
      facilityCount
      troonCardFacilityCount
      hero {
        url
      }
    }
    stateFacilities {
      state {
        id
        name
      }
      facilities {
        ...FacilityCard
      }
    }
  }
}
`);

const getCard = cachedQuery(cardQuery, {
	onError: (error) => {
		if (error.graphQLErrors[0]?.message.toLowerCase().includes('not found')) {
			throw new Error404(error.graphQLErrors[0]);
		}
	},
});
